import { SnackbarProvider } from 'notistack';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import eruda from 'eruda';

import Routes from './routes';
import * as Helpers from './helpers';
import successImg from './assets/images/success.png';
import errorImg from './assets/images/error.png';

if (process.env.REACT_APP_NODE_ENV !== 'production') {
  eruda.init({
    autoScale: true,
    useShadowDom: true,
  });
}

class Carrot extends React.Component {
  render() {
    return (
      <SnackbarProvider
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        ref={Helpers.notification.reference}
        action={key => <div onClick={() => Helpers.notification.close(key)}>DISMISS</div>}
        content={(key, obj) => {
          if (window.location.pathname === '/') {
            return <div />;
          }
          let error = false;
          let message = obj;
          let title = '';
          try {
            const notification = JSON.parse(obj);
            error = notification.error;
            message = notification.message;
            title = notification.title;
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Unable to parse notification object:', e);
          }
          return (
            <div className="notification" id={key}>
              <img
                className="notification-img"
                src={error ? errorImg : successImg}
                alt={error ? 'Error' : 'Success'}
              />
              <div>
                <div className="notification-title">{title}</div>
                <span className="notification-content">{message}</span>
              </div>
            </div>
          );
        }}
      >
        <Routes />
      </SnackbarProvider>
    );
  }
}

export default Carrot;
