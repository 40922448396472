import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';

import * as Helpers from '../helpers';

class PrivateRoutes extends Component {
  render() {
    return (
      Helpers.token.get('admin:token')
        ? <Route {...this.props} /> : <Redirect to="/" />
    );
  }
}

export default PrivateRoutes;
