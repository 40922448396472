import * as React from 'react';
import {
  Chart, ChartTitle, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem
} from '@progress/kendo-react-charts';
import Box from '@mui/material/Box';
import 'hammerjs';
import './Graph.css';

export default function DashboardGraph(props) {
  const {
    graphData,
  } = props;
  const categories = [];

  return (
    <Box>
      <Box
        className="colorKey"
      >
        <h6 className="c2">⬤ Withdrawals</h6>
        <h6 className="c1">⬤ Users</h6>
        <h6 className="c3">⬤ Withdrawal Amount</h6>
        <h6 className="c4">⬤ Loans</h6>
        <h6 className="c5">⬤ Loan Principal</h6>
        <h6 className="c5">⬤ Loan Interest</h6>
        <h6 className="c4">⬤ Repayments</h6>
        <h6 className="c5">⬤ Repayment Principal</h6>
        <h6 className="c5">⬤ Repayment Interest</h6>
      </Box>

      <Chart>
        <ChartTitle text="" />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            title={{
              text: 'Time',
            }}
            categories={categories}
          />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem type="line" data={graphData.user.total} />
          <ChartSeriesItem type="line" data={graphData.withdrawal.total} />
          <ChartSeriesItem type="line" data={graphData.withdrawal.amount} />
          <ChartSeriesItem type="line" data={graphData.loan.total} />
          <ChartSeriesItem type="line" data={graphData.loan.principal} />
          <ChartSeriesItem type="line" data={graphData.loan.interest} />
          <ChartSeriesItem type="line" data={graphData.repayment.total} />
          <ChartSeriesItem type="line" data={graphData.repayment.principal} />
          <ChartSeriesItem type="line" data={graphData.repayment.interest} />
        </ChartSeries>
      </Chart>
    </Box>

  );
}

