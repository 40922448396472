import React, {
  useState, useEffect, useCallback, useMemo
} from 'react';
import PropTypes from 'prop-types';
import sha256 from 'sha256';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import MuiButton from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';

import {
  DatePicker, Loading, Button, Menu
} from '../../components';

import DashboardCard from '../../components/DashboardCard';

import * as Helpers from '../../helpers';

import './TableList.css';
import errorImg from '../../assets/images/error.png';

const drawerWidth = 240;

const today = new Date();
const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
const yesterdayMonth = yesterday.getMonth() + 1 > 9 ? yesterday.getMonth() + 1 : `0${yesterday.getMonth() + 1}`;
const tomorrowMonth = tomorrow.getMonth() + 1 > 9 ? tomorrow.getMonth() + 1 : `0${tomorrow.getMonth() + 1}`;
const yesterdayDay = yesterday.getDate() > 9 ? yesterday.getDate() : `0${yesterday.getDate()}`;
const tomorrowDay = tomorrow.getDate() > 9 ? tomorrow.getDate() : `0${tomorrow.getDate()}`;
const toDate = `${tomorrow.getFullYear()}/${tomorrowMonth}/${tomorrowDay}`;
const fromDate = `${yesterday.getFullYear()}/${yesterdayMonth}/${yesterdayDay}`;

Helpers.token.set(toDate, 'date:to');
Helpers.token.set(fromDate, 'date:from');

function TableList(props) {
  const {
    getAll,
    clearAll,
    type,
    admin: {
      all, email, loading, permissions, total,
    },
    reject,
    refresh,
    issueLoan,
    history,
    completeWithdrawal,
  } = props;
  const { location } = history;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(Helpers.token.get(`${type}:rows_per_page`), 10) || 100);
  const [to, setTo] = useState(Helpers.token.get('date:to') ? Helpers.token.get('date:to') : toDate);
  const [from, setFrom] = useState(Helpers.token.get('date:from') ? Helpers.token.get('date:from') : fromDate);
  const [actionID, setActionID] = useState(0);
  const [search, setSearch] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [status, setStatus] = useState('all');
  const [dateTimeout, setDateTimeout] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [keys, setKeys] = useState([]);

  const matches = useMediaQuery('(min-width: 768px)');

  const loadingTable = useMemo(() => loading.some(url => url
    === `/admin/${type}?limit=${rowsPerPage}&offset=${page * rowsPerPage}&from=${from}&to=${to}${
      JSON.parse(Helpers.token.get('table:search')) ? `&search=${Helpers.token.get('table:search')}` : ''
    }`), [from, loading, page, rowsPerPage, to, type]);
  const loadingReject = useMemo(() => loading.some(url => url === '/admin/reject'), [loading]);
  const loadingRefresh = useMemo(() => loading.some(url => url === '/admin/portfolio'), [loading]);
  const loadingIssue = useMemo(() => loading.some(url => url === '/admin/issueLoan'), [loading]);
  const pathname = location.pathname.split('/')[1];
  const searchKeys = useMemo(() => ({
    [`${pathname}_id`]: '',
    ...(pathname !== 'application' ? { user_id: '' } : {}),
    ...(
      pathname === 'user'
        ? {
          email: '', firstname: '', lastname: '', mobile: '',
        }
        : {}
    ),
  }), [pathname]);

  const findIndexOfLongest = useCallback(arr => {
    let det = [0, 0]; // [index, length]
    arr.forEach((curr, i) => {
      const len = Object.keys(curr).length;
      det = len > det[1] ? [i, len] : det;
    });
    return det[0];
  }, []);

  const getData = useCallback((clear = true) => {
    if (clear) {
      clearAll();
    }

    setTimeout(() => {
      getAll(
        type,
        rowsPerPage,
        page * rowsPerPage,
        Helpers.token.get('date:from'),
        Helpers.token.get('date:to'),
        JSON.parse(Helpers.token.get('table:search')) ? Helpers.token.get('table:search') : null,
        res => {
          setIsTyping(false);
          const indexOfLongest = findIndexOfLongest(res.data.all);
          setKeys(Object.keys(res.data.all[indexOfLongest]));
        },
        () => {
          setIsTyping(false);
        }
      );
    }, 1000);
  }, [clearAll, getAll, type, rowsPerPage, page, findIndexOfLongest]);

  useEffect(() => {
    if (firstLoad) {
      Helpers.token.set(null, 'table:search');
      setFirstLoad(false);
    } else {
      getData();
    }
  }, [firstLoad, getData]);

  // format is '{"lastname":"Nna"}' or `{"lastname":"Nna"}`
  const handleSearch = useCallback((e, source) => {
    let searchValue = {};
    if (source === 'search') {
      searchValue = Object.keys(searchKeys).reduce((prev, current) => ({
        ...prev, [current]: e.target.value,
      }), {});
      if (status !== 'all') {
        searchValue.status = status;
      }
    } else {
      searchValue = JSON.parse(Helpers.token.get('table:search')) ? JSON.parse(Helpers.token.get('table:search')) : {};
      if (source === 'status') {
        if (e.target.value === 'all') {
          delete searchValue.status;
        } else {
          searchValue.status = e.target.value;
        }
      }
    }

    if (Object.keys(searchValue).length === 0) {
      Helpers.token.set(null, 'table:search');
    } else {
      Helpers.token.set(JSON.stringify(searchValue), 'table:search');
    }

    // eslint-disable-next-line no-console
    console.debug('Searching With:', searchValue);
  }, [searchKeys, status]);

  const handleInputSearch = useCallback(e => {
    clearTimeout(dateTimeout);

    setSearch(e.target.value);
    handleSearch(e, 'search');

    setDateTimeout(setTimeout(() => {
      if (page === 0) {
        getData();
      } else {
        setPage(0);
      }
      setDateTimeout(null);
    }, 2000));
  }, [dateTimeout, getData, handleSearch, page]);

  const handleClearSearch = useCallback(() => {
    Helpers.token.set(null, 'table:search');
    setStatus('all');
    setSearch('');
    getData();
  }, [getData]);

  const rejectLoan = useCallback(id => () => {
    setActionID(id);
    if (Helpers.permission(['update-loan-status-reject'], permissions)) {
      reject(
        id,
        res => {
          Helpers.notification.success(res.message);
          getData(false);
        },
        err => {
          Helpers.notification.success(err.message);
        }
      );
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-loan-status-reject]');
    }
  }, [permissions, reject, getData]);

  const loanIssue = useCallback(id => () => {
    setActionID(id);
    if (Helpers.permission(['update-loan-status-issue'], permissions)) {
      issueLoan(
        id,
        res => {
          getData(false);
          Helpers.notification.success(res.message);
        },
        err => {
          Helpers.notification.error(err);
        }
      );
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-loan-status-issue]');
    }
  }, [getData, issueLoan, permissions]);

  const refreshPortfolio = useCallback((id, application_id) => () => {
    setActionID(id);
    refresh(
      id,
      application_id,
      res => {
        Helpers.notification.success(res.message);
        getData(false);
      },
      err => {
        Helpers.notification.error(err.message);
      }
    );
  }, [refresh, getData]);

  const setDate = useCallback(which => val => {
    clearTimeout(dateTimeout);
    if (which === 'to') {
      setTo(val);
      Helpers.token.set(val, 'date:to');
    } else if (which === 'from') {
      setFrom(val);
      Helpers.token.set(val, 'date:from');
    }
    setDateTimeout(setTimeout(() => {
      getData();
      setDateTimeout(null);
    }, 1000));
  }, [getData, dateTimeout]);

  // const goTo = useCallback((e, newValue) => {
  //   history.push(`/${type}/${newValue[`${type}_id`]}`);
  // }, [history, type]);

  useEffect(() => {
    setTimeout(() => isTyping && setIsTyping(false), 1000);
  }, [isTyping]);

  const reload = useCallback(() => {
    window.location = `/${type}`;
    return null;
  }, [type]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(event => {
    setRowsPerPage(+event.target.value);
    Helpers.token.set(+event.target.value, `${type}:rows_per_page`);
    setPage(0);
  }, [type]);

  const handleFilterChange = useCallback(event => {
    setStatus(event.target.value);
    handleSearch(event, 'status');
    if (page === 0) {
      getData();
    } else {
      setPage(0);
    }
  }, [getData, handleSearch, page]);

  const [withdrawalModalOpen, setWithdrawalModalOpen] = useState(false);

  // modal-box style
  const style = {
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    bottom: '10%',
    boxShadow: 24,
    left: '50%',
    p: 4,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    width: 500,
  };

  // complete withdrawal
  const loadingCompleted = useMemo(() => loading.some(url => url === '/admin/withdrawal/complete'), [loading]);

  const handleWithdrawalModalOpen = useCallback(() => setWithdrawalModalOpen(true), []);
  const handleWithdrawalModalClose = useCallback(() => {
    setWithdrawalModalOpen(false);
  }, []);

  const handleWithdrawalCompleteSubmit = useCallback((event, withdrawal_id) => {
    event.preventDefault();
    setActionID(withdrawal_id);
    if (Helpers.permission(['update-withdrawal-status-complete'], permissions)) {
      handleWithdrawalModalOpen();
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-withdrawal-status-complete]');
    }
  }, [handleWithdrawalModalOpen, permissions]);

  // complete withdrawal
  const withdrawalComplete = useCallback(() => {
    completeWithdrawal(
      actionID,
      res => {
        getData(false);
        Helpers.notification.success(res.message);
      },
      err => {
        Helpers.notification.error(err.message);
      }
    );
    handleWithdrawalModalClose();
  }, [actionID, completeWithdrawal, getData, handleWithdrawalModalClose]);

  // Set extra columns on the table
  all.map((item, i) => {
    const hash = sha256(item[`${type}_id`] || '').substr(0, 8);
    const label = `${item[`${type}_id`]} (${hash})`;
    const obj = {
      [`${type} hash id`]: hash,
      ...item,
      action: type === 'withdrawal',
      issue: type === 'loan' && Helpers.permission(['update-loan-status-issue'], permissions),
      refresh: type === 'portfolio',
      reject: type === 'loan' && Helpers.permission(['update-loan-status-reject'], permissions),
    };
    all[i] = Object.keys(obj).filter(key => !!obj[key]).reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: obj[currentValue],
      }),
      {}
    );
    return {
      ...all[i],
      label: type === 'user'
        ? `${item.email} (${item.firstname} ${item.lastname})` : label,
    };
  });

  const totalObj = {
    liquidation: {
      total: 0,
    },
    loan: {
      interest: 0,
      principal: 0,
      total: 0,
    },
    portfolio: {
      amount: 0,
      total: 0,
    },
    repayment: {
      interest: 0,
      principal: 0,
      total: 0,
    },
    user: {
      total: 0,
    },
    withdrawal: {
      amount: 0,
      total: 0,
    },
  };

  if (total && total.length > 0) {
    totalObj[type].total = total[0].total;
    if (type === 'loan' || type === 'repayment') {
      totalObj[type].principal = total[0].principal;
      totalObj[type].interest = total[0].interest;
    }
    if (type === 'withdrawal' || type === 'portfolio') {
      totalObj[type].amount = total[0].amount;
    }
  }

  const csvAll = arr => {
    let deleteKeys = [];
    if (type === 'user' && !Helpers.permission(['read-user-full'], permissions)) deleteKeys = ['bvn', 'card', 'code', 'password', 'mobile', 'email', 'balance', 'token'];
    if (type === 'portfolio' && !Helpers.permission(['read-portfolio-full'], permissions)) deleteKeys = ['amount'];
    if (type === 'withdrawal' && !Helpers.permission(['read-withdrawal-full'], permissions)) deleteKeys = ['details'];
    if (type === 'repayment' && !Helpers.permission(['read-repayment-full'], permissions)) deleteKeys = ['reference'];

    const arrCopy = [...arr];
    return arrCopy.map(object => {
      const obj = { ...object };
      deleteKeys.forEach(item => {
        if (obj[item]) obj[item] = 'NP';
      });
      return obj;
    });
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar>
          <Toolbar
            sx={{
              background: 'white',
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{
                flexDdirection: 'row',
                mr: 2,
              }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { sm: 'block', xs: 'none' }, flexGrow: 1 }}
            >
              carrot
            </Typography>

            <FormControl
              sx={{ width: matches ? 'calc(100% - 240px)' : '100%' }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                onChange={handleInputSearch}
                value={search}
                endAdornment={(
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )}
                label="search"
              />
            </FormControl>
          </Toolbar>
        </AppBar>
        {/* <Toolbar /> */}
      </Box>

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <Menu
          {...props}
          email={email}
          open={mobileOpen}
          width={drawerWidth}
          toggle={handleDrawerToggle}
        />

        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
          style={{ maxWidth: '100%' }}
        >
          {/* <Toolbar /> */}
          <Typography
            sx={{
              fontSize: '17px',
              m: 0.25,
              p: 0,
            }}
            style={{ marginTop: 60 }}
          >
            SEARCHING FOR:
            {' '}
            {JSON.parse(Helpers.token.get('table:search')) && JSON.parse(Helpers.token.get('table:search'))[`${type}_id`] ? `${type}_id= ${JSON.parse(Helpers.token.get('table:search'))[`${type}_id`]};` : ''}
            {' '}
            {JSON.parse(Helpers.token.get('table:search')) && JSON.parse(Helpers.token.get('table:search')).status ? `status= ${JSON.parse(Helpers.token.get('table:search')).status};` : ''}
            {' '}
            <img src={errorImg} onClick={handleClearSearch} alt="cancel" style={{ cursor: 'pointer', marginBottom: '3px', width: '15px' }} />
          </Typography>

          <Typography
            sx={{
              fontSize: 'h5.fontSize',
              m: 0.25,
              p: 0.5,
            }}
            style={{ marginTop: 30 }}
          >
            {Helpers.capitalizeFirstLetter(type)}
            S (Page
            {' '}
            {page + 1}
            )
          </Typography>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: {
                lg: 'row',
                md: 'row',
                sm: 'column',
                xs: 'column',
              },
              justifyContent: 'right',
              m: 2,
              maxWidth: '100%',
            }}
          >
            <Typography sx={{
              m: 1,
              p: 1,
            }}
            >
              From
            </Typography>

            <DatePicker
              value={from}
              onChange={setDate('from')}
            />

            <Typography sx={{
              m: 1,
              p: 1,
            }}
            >
              To
            </Typography>

            <DatePicker
              value={to}
              onChange={setDate('to')}
            />

            <Box
              sx={{
                ml: 2,
              }}
            >
              <CSVLink
                data={csvAll(all)}
                filename={`${type}s-${from}-to-${to}-pg-${page + 1}.csv`}
                className="btn btn-primary csvButton"
              >
                {Helpers.capitalizeFirstLetter('Export as CSV')}
              </CSVLink>
            </Box>

          </Box>

          <Box sx={{ display: (type === 'loan' || type === 'withdrawal') ? 'flex' : '', width: 10 }}>
            <DashboardCard title={`TOTAL ${Helpers.capitalizeFirstLetter(type)}S`} value={totalObj[type]} type={type} />

            <FormControl fullWidth sx={{ display: (type === 'loan' || type === 'withdrawal') ? '' : 'none', minWidth: 120, mt: '19px' }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Status"
                autoWidth
                onChange={handleFilterChange}
                defaultValue="all"
              >
                {
                  (type === 'loan' ? ['all', 'requested', 'cancelled', 'rejected', 'issued', 'disbursing', 'disbursed', 'serviced', 'repaid']
                    : type === 'withdrawal' ? ['all', 'pending', 'cancelled', 'rejected', 'processing', 'completed'] : ['all'])
                    .map((sta, i) => <MenuItem key={i} value={sta}>{sta}</MenuItem>)
                }
              </Select>
            </FormControl>
          </Box>

          {
            loadingTable || dateTimeout
              ? <Loading size="big" />
              : (
                <Paper sx={{ overflow: 'hidden', width: '100%' }}>
                  <TableContainer sx={{ maxHeight: '80vh' }}>
                    <Table stickyHeader aria-label="sticky table">
                      <caption>
                        {
                    all.length === 0
                      ? (
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            m: 2,
                          }}
                        >
                          <span>
                            There are no
                            {' '}
                            {type}
                            s for the selected period.
                          </span>
                          <MuiButton variant="outlined" color="info" size="medium" onClick={reload}>
                            Refresh
                          </MuiButton>
                        </Box>
                      ) : `These are ${type}s for the selected period only.`
                  }
                      </caption>

                      <TableHead>
                        <TableRow>
                          {all.length === 0 || loadingTable ? null : keys.map((key, i) => {
                            let heading = key;
                            heading = key === 'info' ? 'debt' : heading;
                            const objectColumn = (type !== 'repayment' && key === 'raw') || key === 'metadata';
                            if (objectColumn) return null;

                            if (type === 'repayment' && key === 'raw') heading = 'author';
                            return (
                              <TableCell
                                key={i}
                                align="center"
                                style={{ minWidth: 150 }}
                              >
                                {Helpers.capitalizeFirstLetter(heading)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {all.length === 0 ? null : loadingTable
                          ? <Loading size="big" />
                          : all
                            .map((item, i) => (
                              <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                {keys.map((key, j) => {
                                  let val = item[key];
                                  const exempted = (key === 'card' || key === 'code' || key === 'password' || key === 'token' || key === 'legal');
                                  const isUserStatus = type === 'user' && key === 'status';
                                  const objectColumn = (type !== 'repayment' && key === 'raw') || key === 'metadata';
                                  let noShow = null;
                                  if (type === 'portfolio' && !Helpers.permission(['read-portfolio-full'], permissions)) noShow = key === 'amount';
                                  if (type === 'user' && !Helpers.permission(['read-user-full'], permissions)) noShow = key === 'bvn' || key === 'mobile' || key === 'email' || key === 'balance';
                                  if (type === 'withdrawal' && !Helpers.permission(['read-withdrawal-full'], permissions)) noShow = key === 'details';
                                  if (type === 'repayment' && !Helpers.permission(['read-repayment-full'], permissions)) noShow = key === 'reference';

                                  if (objectColumn) return null;
                                  if (noShow) {
                                    return (
                                      <TableCell key={j} align="center" title="You do not have the necessary permission(s)">
                                        NP
                                      </TableCell>
                                    );
                                  }

                                  if (type === 'repayment' && key === 'raw') val = val.author;
                                  if (/_id$/.test(key) && !/application/.test(key)) {
                                    const pageName = key.split('_')[0];
                                    return (
                                      <TableCell key={j} align="center" title={`View ${pageName} details`}>
                                        <Link to={`${pageName}/${val}`}>{val}</Link>
                                      </TableCell>
                                    );
                                  }
                                  return (
                                    <TableCell key={j} align="center" title={typeof val === 'object' || typeof val === 'boolean' ? '' : val}>
                                      {
                                ((type === 'loan' && key === 'reject') || (type === 'loan' && key === 'issue') || (type === 'portfolio' && key === 'refresh'))
                                  ? (
                                    type === 'portfolio' || (key === 'reject' && item.status === 'issued') || (key === 'issue' && item.status === 'requested')
                                      ? (
                                        <Button
                                          type={type === 'loan' && key === 'reject' ? 'error' : 'info'}
                                          loading={(item.loan_id === actionID && loadingReject) || (item.loan_id === actionID && loadingIssue) || (item.user_id === actionID && loadingRefresh)}
                                          onClick={type === 'loan' && key === 'reject' ? rejectLoan(item.loan_id) : type === 'loan' && key === 'issue' ? loanIssue(item.loan_id) : refreshPortfolio(item.user_id, item.application_id)}
                                        >
                                          {type === 'loan' && key === 'reject' ? 'Reject' : type === 'loan' && key === 'issue' ? 'Issue' : 'Refresh'}
                                        </Button>
                                      )
                                      : 'N/A'
                                  )
                                  : (type === 'withdrawal' && key === 'action')
                                    ? (item.status === 'processing' && item.processor_id !== '1')
                                      ? (
                                        <Button
                                          type="info"
                                          loading={item.withdrawal_id === actionID && loadingCompleted}
                                          onClick={e => handleWithdrawalCompleteSubmit(e, item.withdrawal_id)}
                                        >
                                          Complete
                                        </Button>
                                      )
                                      : 'N/A'
                                    : isUserStatus && val
                                      ? 'ACTIVE'
                                      : isUserStatus && !val
                                        ? 'INACTIVE'
                                        : exempted && !!val
                                          ? 'YES'
                                          : exempted && !val
                                            ? 'NO'
                                            : typeof val === 'object'
                                              ? JSON.stringify(val)
                                              : val
                              }
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 2000]}
                    component="div"
                    count={parseInt(totalObj[type].total, 10)}
                    rowsPerPage={rowsPerPage}
                    page={parseInt(totalObj[type].total, 10) <= 0 ? 0 : page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              )
          }

          <div>
            <Modal
              open={withdrawalModalOpen}
              onClose={handleWithdrawalModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want mark this withdrawal as completed?
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    mt: 3,
                  }}
                >
                  <Button
                    sx={{
                    }}
                    onClick={handleWithdrawalModalClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                    }}
                    onClick={withdrawalComplete}
                    name="completeWithdrawalConfirmation"
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Modal>
          </div>
        </Box>
      </Box>
    </div>
  );
}

TableList.propTypes = {
  type: PropTypes.string,
};

export default TableList;
