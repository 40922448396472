import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export default function BasicDatePicker(props) {
  const { value, onChange } = props;
  const [oldValue, setOldValue] = React.useState(value);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="mm/dd/yy"
        value={oldValue}
        onChange={newValue => {
          setOldValue(newValue);
          const month = newValue.getMonth() + 1 > 9 ? newValue.getMonth() + 1 : `0${newValue.getMonth() + 1}`;
          const day = newValue.getDate() > 9 ? newValue.getDate() : `0${newValue.getDate()}`;
          const date = `${newValue.getFullYear()}/${month}/${day}`;
          onChange(date);
        }}
        renderInput={params => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
