import * as React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from './Button';

export default function DashboardCard(props) {
  const { title, value, type } = props;
  return (
    <Card sx={{ flexGrow: 1, m: 2, minWidth: 250 }}>
      <CardContent>

        <Typography variant="h6" component="div">
          {title}
        </Typography>
        <Typography>
          { `Total ${type}s: ${value.total}` }
        </Typography>
        {type === 'loan' || type === 'repayment' ? (
          <>
            <Typography>
              {`Total principal: $${value.principal / 100}`}
            </Typography>
            <Typography>
              {`Total interest: $${value.interest / 100}`}
            </Typography>
          </>
        ) : ''}
        {type === 'withdrawal' ? (
          <Typography>
            {`Total amount: $${value.amount / 100}`}
          </Typography>
        ) : ''}
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
  );
}
