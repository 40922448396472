import React, {
  useState, useEffect, useCallback
} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import SearchAppBar from '../../components/SearchAppBar';
import DashboardGraph from '../../components/Graph';
import DashboardCard from '../../components/DashboardCard';
import {
  DatePicker, Menu
} from '../../components';
import * as Helpers from '../../helpers';
import './Dashboard.css';

const drawerWidth = 240;

const today = new Date();
const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
const yesterdayMonth = yesterday.getMonth() + 1 > 9 ? yesterday.getMonth() + 1 : `0${yesterday.getMonth() + 1}`;
const tomorrowMonth = tomorrow.getMonth() + 1 > 9 ? tomorrow.getMonth() + 1 : `0${tomorrow.getMonth() + 1}`;
const yesterdayDay = yesterday.getDate() > 9 ? yesterday.getDate() : `0${yesterday.getDate()}`;
const tomorrowDay = tomorrow.getDate() > 9 ? tomorrow.getDate() : `0${tomorrow.getDate()}`;
const toDate = `${tomorrow.getFullYear()}/${tomorrowMonth}/${tomorrowDay}`;
const fromDate = `${yesterday.getFullYear()}/${yesterdayMonth}/${yesterdayDay}`;

Helpers.token.set(toDate, 'date:to');
Helpers.token.set(fromDate, 'date:from');

const typeArr = ['user', 'loan', 'withdrawal', 'repayment'];
const totalObj = {
  loan: {
    interest: 0,
    principal: 0,
    total: 0,
  },
  repayment: {
    interest: 0,
    principal: 0,
    total: 0,
  },
  user: {
    total: 0,
  },
  withdrawal: {
    amount: 0,
    total: 0,
  },
};

const graphData = {
  loan: {
    dateKey: [],
    interest: [],
    principal: [],
    total: [],
  },
  repayment: {
    dateKey: [],
    interest: [],
    principal: [],
    total: [],
  },
  user: {
    dateKey: [],
    total: [],
  },
  withdrawal: {
    amount: [],
    dateKey: [],
    total: [],
  },
};

function groupArrayOfObjects(list, key) {
  return list.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

function Dashboard(props) {
  const {
    getAll,
    clearAll,
    admin: { email },
  } = props;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [to, setTo] = useState(toDate);
  const [from, setFrom] = useState(fromDate);
  const [run, setRun] = useState(false);

  const getData = useCallback((clear = true) => {
    if (clear) {
      clearAll();
    }

    typeArr.forEach(item => {
      setTimeout((type = item) => {
        getAll(
          type,
          null,
          null,
          Helpers.token.get('date:from'),
          Helpers.token.get('date:to'),
          null,
          res => {
            setRun(true);

            if (res.data.total.length > 0) {
              totalObj[type].total = res.data.total[0].total;
              if (type === 'loan' || type === 'repayment') {
                totalObj[type].principal = res.data.total[0].principal;
                totalObj[type].interest = res.data.total[0].interest;
              }
              if (type === 'withdrawal' || type === 'portfolio') {
                totalObj[type].amount = res.data.total[0].amount;
              }
            }

            // Graph
            const allSlice = res.data.all.map(obj => {
              if (type === 'withdrawal') {
                return { ...obj, completed: new Date(obj.completed).toLocaleDateString() };
              } if (type === 'loan') {
                return { ...obj, disbursed: new Date(obj.disbursed).toLocaleDateString() };
              }
              return { ...obj, date: new Date(obj.date).toLocaleDateString() };
            });

            let groupedAll = [];
            if (type === 'withdrawal') {
              groupedAll = groupArrayOfObjects(allSlice, 'completed');
            } else if (type === 'loan') {
              groupedAll = groupArrayOfObjects(allSlice, 'disbursed');
            } else {
              groupedAll = groupArrayOfObjects(allSlice, 'date');
            }

            graphData[type].dateKey = Object.keys(groupedAll); // graphData in graph

            graphData[type].total = [];
            graphData[type].principal = [];
            graphData[type].interest = [];
            graphData[type].amount = [];

            graphData[type].dateKey.forEach(dateKey => {
              graphData[type].total.push(groupedAll[dateKey].length);
              if (type === 'loan' || type === 'repayment') {
                let totalPrincipal = 0;
                let totalInterest = 0;
                groupedAll[dateKey].forEach(value => {
                  const percentInterest = Number(value.interest);
                  const dbPrincipal = Number(value.principal);
                  const realInterest = (percentInterest / 100) * dbPrincipal;
                  totalPrincipal += dbPrincipal;
                  totalInterest += realInterest;
                });
                graphData[type].principal.push(totalPrincipal);
                graphData[type].interest.push(totalInterest);
              }
              if (type === 'withdrawal') {
                let totalAmount = 0;
                groupedAll[dateKey].forEach(value => {
                  totalAmount += Number(value.amount);
                });
                graphData[type].amount.push(totalAmount);
              }
            });
          },
          () => {}
        );
      }, 1000);
    });
  }, [clearAll, getAll]);

  const setDate = useCallback(which => val => {
    if (which === 'to') {
      setTo(val);
      Helpers.token.set(val, 'date:to');
    } else if (which === 'from') {
      setFrom(val);
      Helpers.token.set(val, 'date:from');
    }
    getData();
  }, [getData]);

  useEffect(() => {
    if (run) return;
    getData();
  }, [getData, run]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  return (
    <div>
      <SearchAppBar
        onToggleDrawer={handleDrawerToggle}
        {...props}
      />

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <Menu
          {...props}
          email={email}
          open={mobileOpen}
          width={drawerWidth}
          toggle={handleDrawerToggle}
        />

        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          {/* <Toolbar /> */}
          <Typography sx={{
            fontSize: 'h5.fontSize',
            m: 0.25,
            p: 0.5,
          }}
          >
            DASHBOARD
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                lg: 'row',
                md: 'row',
                sm: 'column',
                xs: 'column',
              },
              // flexDirection: 'row',
              justifyContent: 'right',
              m: 2,
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              mt: 2,
            }}
            >
              <Typography sx={{
                m: 1,
                p: 1,
              }}
              >
                From
              </Typography>

              <DatePicker
                value={from}
                onChange={setDate('from')}
              />
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'right',
              mt: 2,
            }}
            >
              <Typography sx={{
                m: 1,
                p: 1,
              }}
              >
                To
              </Typography>

              <DatePicker
                value={to}
                onChange={setDate('to')}
              />
            </Box>
          </Box>

          {/* Cards   */}
          <Box sx={{
            display: 'flex',
            flexDirection: {
              lg: 'row',
              md: 'row',
              sm: 'column',
              xs: 'column',
            },
            // flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'left',
            mt: 2,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
          >
            <DashboardCard title="TOTAL USERS" value={totalObj.user} type="user" />
            <DashboardCard title="TOTAL LOANS" value={totalObj.loan} type="loan" />
            <DashboardCard title="WITHDRAWALS" value={totalObj.withdrawal} type="withdrawal" />
            <DashboardCard title="REPAYMENTS" value={totalObj.repayment} type="repayment" />
          </Box>

          <Box sx={{
            m: 2,
          }}
          >
            <DashboardGraph graphData={graphData} />
          </Box>

        </Box>

      </Box>
    </div>
  );
}

Dashboard.propTypes = {
  type: PropTypes.string,
};

export default Dashboard;
