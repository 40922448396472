import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import Button from './Button';

// modal-box style
const style = {
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  bottom: '10%',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  width: 500,
};

const SingleModal = props => {
  const {
    modalOpen, modalName, modalTitle, modalContent, actionButtonText,
    actionButtonDisabled, closeButtonText, modalInput, modalSubmit, modalClose,
    inputPlaceholder, inputValue, onInputChange, defaultButtons,
  } = props;

  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {modalTitle}
        </Typography>

        {modalContent}
        {modalInput && (
          <input
            className="modalInput"
            placeholder={inputPlaceholder}
            name={`${modalName}Input`}
            value={inputValue}
            onChange={onInputChange}
            autoComplete="off"
          />
        )}

        {defaultButtons || (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            mt: 3,
          }}
        >
          <Button
            sx={{
            }}
            onClick={modalClose}
          >
            {closeButtonText || 'Cancel'}
          </Button>
          <Button
            disabled={actionButtonDisabled}
            onClick={modalSubmit}
            name={`${modalName}Confirmation`}
          >
            {actionButtonText || 'Yes'}
          </Button>
        </Box>
        )}
      </Box>
    </Modal>
  );
};

export default SingleModal;
