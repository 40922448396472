import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PortfolioIcon from '@mui/icons-material/LocalAtm';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import UserIcon from '@mui/icons-material/Person';
import LoanIcon from '@mui/icons-material/AccountBalance';
import WithdrawalIcon from '@mui/icons-material/AccountBalanceWallet';
import RepaymentIcon from '@mui/icons-material/AssignmentReturn';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';

import * as Helpers from '../helpers';
// import avatarImg from '../assets/images/gravatar1.png';

export default function Menu(props) {
  const {
    email, open, toggle, width, history, clearAll, admin: { permissions },
  } = props;

  const logOut = useCallback(() => {
    Helpers.token.remove('admin:token');
    history.push('/signin');
  }, [history]);

  const handleMenu = text => {
    clearAll();
    setTimeout(() => {
      history.push(`/${text.replace(/s$/, '').toLowerCase()}`);
    }, 1000);
  };

  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          justifyContent: 'center',
          m: 1,
          p: 1,
        }}
      >
        <UserIcon sx={{ fontSize: '50px' }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          justifyContent: 'center',
          m: 1,
          p: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: 'h6.fontSize',
          }}
        >
          {email}
        </Typography>
      </Box>
      <Divider />
      <List>
        {[
          'Dashboard', 'Portfolios', 'Users', 'Loans', 'Withdrawals', 'Repayments', 'Liquidation',
          Helpers.permission(['update-application-status'], permissions) ? 'Application' : '',
          'Defaults',
          Helpers.permission(['update-rates-buy', 'update-rates-sell'], permissions) ? 'Rates' : '',
          Helpers.permission(['read-admin-permissions'], permissions) ? 'Permissions' : '',
        ].filter(item => !!item).map(text => (
          <div key={text} className="link" onClick={() => handleMenu(text)}>
            <ListItem button>
              <ListItemIcon>
                {text === 'Dashboard' && <DashboardIcon />}
                {text === 'Portfolios' && <PortfolioIcon />}
                {text === 'Users' && <UserIcon />}
                {text === 'Loans' && <LoanIcon />}
                {text === 'Withdrawals' && <WithdrawalIcon />}
                {text === 'Repayments' && <RepaymentIcon />}
                {text === 'Liquidation' && <PortfolioIcon />}
                {text === 'Application' && <DesktopWindowsIcon />}
                {text === 'Defaults' && <PortfolioIcon />}
                {text === 'Rates' && <CurrencyExchangeIcon />}
                {text === 'Permissions' && <UserIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          </div>
        ))}
      </List>
      <Divider />
      <List>
        {['Log Out'].map((text, index) => (
          <ListItem button key={text} onClick={text === 'Log Out' ? logOut : null}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { sm: 0 }, width: { sm: width } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={undefined}
        variant="temporary"
        open={open}
        onClose={toggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width },
          display: { sm: 'none', xs: 'block' },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width },
          display: { sm: 'block', xs: 'none' },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
