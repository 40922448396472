import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

export default function Button(props) {
  const {
    onClick, loading, type = 'info', children, disabled,
  } = props;

  return (
    <LoadingButton variant="outlined" color={type} size="small" onClick={onClick} loading={loading} disabled={disabled}>
      {children}
    </LoadingButton>
  );
}
