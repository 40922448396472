import jwtDecode from 'jwt-decode';

import * as Helpers from '../../helpers';

export const ERROR = 'ERROR';
export const LOADING = 'LOADING';
export const LOAD = 'LOAD';
export const SIGNIN = 'SIGNIN';
export const GET_ALL = 'GET_ALL';
export const GET_HISTORY = 'GET_HISTORY';
export const REFRESH = 'REFRESH';
export const REJECT = 'REJECT';
export const SUDO = 'SUDO';
export const UPDATE = 'UPDATE';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ALL = 'CLEAR_ALL';
export const REPAY = 'REPAY';
export const VERIFY = 'VERIFY';
export const GETDEFAULTS = 'GETDEFAULTS';
export const ISSUELOAN = 'ISSUELOAN';
export const UPDATEREPAYMENT = 'UPDATEREPAYMENT';
export const COMPLETEWITHDRAWAL = 'COMPLETEWITHDRAWAL';
export const UPDATERATES = 'UPDATERATES';
export const GET_RATES = 'GET_RATES';
export const BLACKLIST = 'BLACKLIST';
export const LIQUIDATE = 'LIQUIDATE';
export const CHARGEUSER = 'CHARGEUSER';
export const APPROVEWITHDRAWAL = 'APPROVEWITHDRAWAL';
export const LOAN_GET_SINGLE = 'LOAN_GET_SINGLE';
export const USER_GET_BREAKDOWN = 'USER_GET_BREAKDOWN';
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const WAITLIST = 'WAITLIST';

export const load = () => dispatch => {
  const admin = jwtDecode(Helpers.token.get('admin:token'));
  // eslint-disable-next-line no-console
  console.log('loaded', admin);
  dispatch({ payload: admin, type: LOAD });
};

export const signin = (email, password, google_token, success) => Helpers.api(
  '/admin/signin',
  'POST',
  { email, google_token, password },
  { success },
  { error: ERROR, loading: LOADING, responder: SIGNIN }
);

export const clearAll = () => dispatch => {
  dispatch({ type: CLEAR_ALL });
};

export const getAll = (table, limit, offset, from, to, search, success, error) => Helpers.api(
  `/admin/${table}?${
    limit !== null && offset !== null ? `limit=${limit}&offset=${offset}` : ''
  }${
    from
      ? (limit !== null && offset !== null) ? `&from=${from}` : `from=${from}`
      : ''
  }${
    to ? `&to=${to}` : ''
  }${
    search ? `&search=${search}` : ''
  }`,
  'GET',
  { table },
  { error, success },
  { error: ERROR, loading: LOADING, responder: GET_ALL }
);

export const getOne = (table, id) => Helpers.api(
  `/admin/${table}/${id}`,
  'POST',
  {},
  {},
  { error: ERROR, loading: LOADING, responder: UPDATE }
);

export const getHistory = (table, id, type, limit, offset, success, error) => Helpers.api(
  `/admin/history/${table}/${id}?type=${type}${(limit !== null && offset !== null) ? `&limit=${limit}&offset=${offset}` : ''}`,
  'GET',
  {},
  { error, success },
  { error: ERROR, loading: LOADING, responder: GET_HISTORY }
);

export const refresh = (user_id, application_id, success, error) => Helpers.api(
  '/admin/portfolio',
  'PUT',
  { application_id, user_id },
  { error, success },
  { error: ERROR, loading: LOADING, responder: REFRESH }
);

export const reject = (loan_id, success) => Helpers.api(
  '/admin/reject',
  'PUT',
  { loan_id },
  { success },
  { error: ERROR, loading: LOADING, responder: REJECT }
);

export const sudo = user_id => Helpers.api(
  '/admin/sudo',
  'POST',
  { user_id },
  {},
  { error: ERROR, loading: LOADING, responder: UPDATE }
);

export const update = password => Helpers.api(
  '/admin/update',
  'PUT',
  { password },
  {},
  { error: ERROR, loading: LOADING, responder: UPDATE }
);

export const repay = (amount, user_id, repayCurrency, loan_id, success, error) => Helpers.api(
  '/admin/repay',
  'PUT',
  {
    amount, loan_id, repayCurrency, user_id,
  },
  { error, success },
  { error: ERROR, loading: LOADING, responder: REPAY }
);

export const verify = (email, success, error) => Helpers.api(
  '/admin/verify',
  'PUT',
  { email },
  { error, success },
  { error: ERROR, loading: LOADING, responder: VERIFY }
);

export const getDefaults = (application_id, limit, offset, interval, strictMode, success, error) => Helpers.api(
  `/admin/defaults?application_id=${application_id}&limit=${limit}&offset=${offset}&interval=${interval}&strictMode=${strictMode}`,
  'GET',
  {},
  { error, success },
  { error: ERROR, loading: LOADING, responder: GETDEFAULTS }
);

export const issueLoan = (loan_id, success) => Helpers.api(
  '/admin/issue',
  'POST',
  { loan_id },
  { success },
  { error: ERROR, loading: LOADING, responder: ISSUELOAN }
);

export const updateRepayment = (repayment_id, principal, interest, date, success, error) => Helpers.api(
  '/admin/repayment/update',
  'PUT',
  {
    date,
    interest,
    principal,
    repayment_id,
  },
  { error, success },
  { error: ERROR, loading: LOADING, responder: UPDATEREPAYMENT }
);

export const completeWithdrawal = (withdrawal_id, success, error) => Helpers.api(
  '/admin/withdrawal/complete',
  'POST',
  { withdrawal_id },
  { error, success },
  { error: ERROR, loading: LOADING, responder: COMPLETEWITHDRAWAL }
);

export const updateRates = (buy, sell, success, error) => Helpers.api(
  '/admin/rates/update',
  'PUT',
  { buy, sell },
  { error, success },
  { error: ERROR, loading: LOADING, responder: UPDATERATES }
);

export const getRates = (success, error) => Helpers.api(
  '/admin/rates',
  'GET',
  {},
  { error, success },
  { error: ERROR, loading: LOADING, responder: GET_RATES }
);

export const blacklist = (user_id, reason, actions, success, error) => Helpers.api(
  '/admin/blacklist',
  'POST',
  { actions, reason, user_id },
  { error, success },
  { error: ERROR, loading: LOADING, responder: BLACKLIST }
);

export const waitlist = (portfolio_id, status, success, error) => Helpers.api(
  '/admin/waitlist',
  'POST',
  { portfolio_id, reason: 'ADMIN', waitlist: status },
  { error, success },
  { error: ERROR, loading: LOADING, responder: WAITLIST }
);

export const liquidate = (reason, loan_id, amount, note, success, error) => Helpers.api(
  '/liquidate',
  'POST',
  {
    amount, loan_id, note, reason,
  },
  { error, success },
  { error: ERROR, loading: LOADING, responder: LIQUIDATE }
);

export const chargeUser = (user_id, amount, success, error) => Helpers.api(
  '/admin/charge',
  'POST',
  { amount, user_id },
  { error, success },
  { error: ERROR, loading: LOADING, responder: CHARGEUSER }
);

export const approveWithdrawal = (withdrawal_id, approve, success, error) => Helpers.api(
  '/admin/withdrawal/approve',
  'POST',
  { approve, withdrawal_id },
  { error, success },
  { error: ERROR, loading: LOADING, responder: APPROVEWITHDRAWAL }
);

export const getSingleLoan = (loan_id, lock, success, error) => Helpers.api(
  `/loan/${loan_id}?lock=${lock}`,
  'GET',
  {},
  { error, success },
  { error: ERROR, loading: LOADING, responder: LOAN_GET_SINGLE }
);

export const getUserBreakdown = (user_id, success, error) => Helpers.api(
  `/admin/loan/breakdown?user_id=${user_id}`,
  'GET',
  {},
  { error, success },
  { error: ERROR, loading: LOADING, responder: USER_GET_BREAKDOWN }
);

export const updatePermission = (adminId, action, permission, success, error) => Helpers.api(
  '/admin/permission/update',
  'PUT',
  { action, adminId, permission },
  { error, success },
  { error: ERROR, loading: LOADING, responder: UPDATE_PERMISSION }
);
