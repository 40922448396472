/* eslint-disable no-case-declarations */
import * as Helpers from '../../helpers';

import {
  ERROR,
  LOADING,
  LOAD,
  SIGNIN,
  CLEAR_ALL,
  GET_ALL,
  GET_HISTORY,
  REPAY,
  VERIFY,
  GETDEFAULTS,
  GET_RATES
} from '../actions';

export const initialState = {
  admin_id: 0,
  all: [],
  application_id: 0,
  defaults: [],
  email: '',
  level: 0,
  loading: [],
  name: '',
  permissions: [],
  rates: {
    buy: 1,
    sell: 1,
  },
  timestamp: '',
  token: '',
  total: [],
};

export default function admin(state = initialState, action) {
  const { loading } = state;
  const { payload } = action;
  switch (action.type) {
  case LOADING:
    return {
      ...state,
      loading: loading.some(item => item === payload)
        ? loading.filter(item => item !== payload)
        : [...loading, payload],
    };

  case ERROR:
    if (payload && payload.data && payload.data.error) {
      payload.data.error.map(err => Helpers.notification.error(err));
    } else if (payload && payload.message) {
      Helpers.notification.error(payload.message);
    } else {
      Helpers.notification.error('Unfortunately we were unable to fetch some data. Try again.');
    }
    return state;

  case LOAD:
    return {
      ...state,
      admin_id: payload.admin_id,
      email: payload.email,
      level: payload.level,
      name: payload.name,
      permissions: payload.permissions,
      timestamp: payload.timestamp,
    };

  case SIGNIN:
    Helpers.token.set(payload.data.token, 'admin:token');
    return {
      ...state,
      token: payload.data.token,
    };

  case CLEAR_ALL:
    return {
      ...state,
      all: [],
    };

  case GET_ALL:
    // eslint-disable-next-line no-console
    console.log('load table payload', payload);
    return {
      ...state,
      all: payload.data.all,
      total: payload.data.total,
    };

  case GET_HISTORY:
    return state;

  case REPAY:
    return state;

  case VERIFY:
    return state;

  case GETDEFAULTS:
    return {
      ...state,
      defaults: payload.data.all,
    };
  case GET_RATES:
    return {
      ...state,
      rates: {
        buy: payload.data.rates.buy,
        sell: payload.data.rates.sell,
      },
    };

  default:
    return state;
  }
}
