import React, {
  useState, useCallback, useEffect, useMemo
} from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';

import { Button } from '../../components';

import SearchAppBar from '../../components/SearchAppBar';
import './Rates.css';
import * as Helpers from '../../helpers';

// modal-box style
const style = {
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  bottom: '10%',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  width: 500,
};

function Rates(props) {
  const {
    history: navHistory,
    admin: { loading, rates, permissions },
    updateRates,
    getRates,
  } = props;

  const [inputDisplay, setInputDisplay] = useState({
    buy: '',
    sell: '',
  });

  const getCurrentRate = useCallback(() => {
    setTimeout(() => {
      getRates(
        res => {
          // eslint-disable-next-line no-console
          console.log(res);
          Helpers.notification.success(res.message);
        },
        err => {
          // eslint-disable-next-line no-console
          console.log(err);
          Helpers.notification.error(err);
        }
      );
    }, 500);
  }, [getRates]);

  useEffect(() => {
    getCurrentRate();
  }, [getCurrentRate]);

  const handleChange = type => event => {
    setInputDisplay({
      ...inputDisplay,
      [type]: event.target.value,
    });
  };

  const loadingUpdateRates = useMemo(() => loading.some(url => url === '/admin/rates/update'), [loading]);

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = useCallback(() => setModalOpen(true), []);
  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleFormSubmit = useCallback(event => {
    event.preventDefault();
    if (Helpers.permission(['update-rates-buy', 'update-rates-sell'], permissions)) {
      if (inputDisplay.buy || inputDisplay.sell) {
        handleModalOpen();
      } else {
        Helpers.notification.error('No values to submit!');
      }
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-rates-buy, update-rates-sell]');
    }
  }, [handleModalOpen, inputDisplay.buy, inputDisplay.sell, permissions]);

  const handleUpdateRates = useCallback(() => {
    updateRates(
      inputDisplay.buy,
      inputDisplay.sell,
      res => {
        handleModalClose();
        Helpers.notification.success(res.message);
        getRates();
        setInputDisplay({
          buy: '',
          sell: '',
        });
      },
      err => {
        Helpers.notification.error(err && err.message);
        handleModalClose();
      }
    );
  }, [getRates, handleModalClose, inputDisplay.buy, inputDisplay.sell, updateRates]);

  return (
    <>
      <SearchAppBar back={navHistory.goBack} buttonName="back" />

      <div
        id="rates"
      >
        <Typography
          variant="h5"
          sx={{
					  m: '30px',
          }}
        >
          SET RATES
        </Typography>

        <div
          className="ratesForm"
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              m: 'auto',
              mb: '20px',
              width: '70%',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                textAlign: 'left',
              }}
              className="marginRightWide"
            >
              <Typography
                sx={{
                  fontSize: '1.2rem',
                  m: '10px',
                  ml: 0,
                  textAlign: 'left',
                }}
              >
                Current Buy Rate:
                {' '}
                {rates.buy}
              </Typography>

              <input
                className="ratesInput"
                placeholder="New Buy Rate"
                name="buy"
                value={inputDisplay.buy}
                onChange={handleChange('buy')}
                autoComplete="off"
              />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                textAlign: 'left',
              }}
              className="marginLeftWide"
            >
              <Typography
                sx={{
                  fontSize: '1.2rem',
                  m: '10px',
                  ml: 0,
                  textAlign: 'left',
                }}
              >
                Current Sell Rate:
                {' '}
                {rates.sell}
              </Typography>
              <input
                className="ratesInput"
                placeholder="New Sell Rate"
                name="sell"
                value={inputDisplay.sell}
                onChange={handleChange('sell')}
                autoComplete="off"
              />
            </Box>
          </Box>

          <Box>
            <LoadingButton
              type="info"
              className="submitRatesButton"
              loading={loadingUpdateRates}
              onClick={handleFormSubmit}
            >
              SUBMIT
            </LoadingButton>
          </Box>
        </div>
      </div>

      <div>
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Are you sure you want to set:
              <br />
              Buy Rate:
              {' '}
              {inputDisplay.buy}
              <br />
              Sell Rate:
              {' '}
              {inputDisplay.sell}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                mt: 3,
              }}
            >
              <Button
                sx={{
                }}
                onClick={handleModalClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                }}
                onClick={handleUpdateRates}
                name="updateConfirmation"
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default Rates;
