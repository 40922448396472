import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import SearchAppBar from '../components/SearchAppBar';
import * as Helpers from '../helpers';
import './History/History.css';

function Application(props) {
  const {
    history: navHistory,
    verify,
    admin: { permissions },
  } = props;

  const [inputDisplay, setInputDisplay] = useState({
    value: '',
  });

  const handleChange = event => {
    setInputDisplay({
      value: event.target.value,
    });
  };

  const handleVerify = event => {
    event.preventDefault();
    const { name, value } = event.target[0];
    const email = value;
    if (name === 'verifyEmail') {
      if (Helpers.permission(['update-application-status'], permissions)) {
        verify(
          email,
          successRes => {
            Helpers.notification.success(successRes.message);
          },
          errorRes => {
            Helpers.notification.error(errorRes.message);
          }
        );
      } else {
        Helpers.notification.error('You do not have the necessary permission(s): [update-application-status]');
      }
    }
    setInputDisplay({
      value: '',
    });
  };

  return (
    <>
      <SearchAppBar back={navHistory.goBack} buttonName="back" />

      <Box
        sx={{
          ml: '2rem',
          mt: '2rem',
          textAlign: 'left',
          width: '50%',
        }}
      >
        <Typography
          variant="h6"
          component="div"
          className="dollar-repayment"
        >
          Verify an Application Email
        </Typography>
        <form
          onSubmit={handleVerify}
          className="singleUserForm"
        >
          <input
            className="singleUserInput less-left-padding"
            placeholder="Input an application email"
            name="verifyEmail"
            value={inputDisplay.value}
            onChange={handleChange}
            autoComplete="off"
          />
          <button
            className="singleUserButton noBorderOutline"
            type="submit"
          >
            SUBMIT
          </button>
        </form>
      </Box>
    </>
  );
}

export default Application;
