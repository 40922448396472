import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './store';
import Carrot from './Carrot';

import './index.css';

// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Provider store={store}>
    <Carrot />
  </Provider>,
  document.getElementById('root')
);
