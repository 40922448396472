import React, {
  useState, useEffect, useCallback, useMemo
} from 'react';
import { CSVLink } from 'react-csv';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import MuiButton from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

import {
  DatePicker, Loading, Menu, Button, SingleModal
} from '../../components';
import DashboardCard from '../../components/DashboardCard';

import * as Helpers from '../../helpers';

import './Permissions.css';
import errorImg from '../../assets/images/error.png';

const drawerWidth = 240;

const today = new Date();
const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
const yesterdayMonth = yesterday.getMonth() + 1 > 9 ? yesterday.getMonth() + 1 : `0${yesterday.getMonth() + 1}`;
const tomorrowMonth = tomorrow.getMonth() + 1 > 9 ? tomorrow.getMonth() + 1 : `0${tomorrow.getMonth() + 1}`;
const yesterdayDay = yesterday.getDate() > 9 ? yesterday.getDate() : `0${yesterday.getDate()}`;
const tomorrowDay = tomorrow.getDate() > 9 ? tomorrow.getDate() : `0${tomorrow.getDate()}`;
const toDate = `${tomorrow.getFullYear()}/${tomorrowMonth}/${tomorrowDay}`;
const fromDate = `${yesterday.getFullYear()}/${yesterdayMonth}/${yesterdayDay}`;

Helpers.token.set(toDate, 'date:to');
Helpers.token.set(fromDate, 'date:from');

function Permissions(props) {
  const {
    getAll,
    clearAll,
    admin: {
      all, email, loading, permissions, total,
    },
    updatePermission,
  } = props;
  const type = 'admin';

  const [mobileOpen, setMobileOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(Helpers.token.get(`${type}:rows_per_page`), 10) || 100);
  const [to, setTo] = useState(Helpers.token.get('date:to') ? Helpers.token.get('date:to') : toDate);
  const [from, setFrom] = useState(Helpers.token.get('date:from') ? Helpers.token.get('date:from') : fromDate);
  const [search, setSearch] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [status, setStatus] = useState('all');
  const [dateTimeout, setDateTimeout] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [keys, setKeys] = useState([]);
  const [permissionValue, setPermissionValue] = useState('');
  const [actionID, setActionID] = useState(0);
  const [actionEmail, setActionEmail] = useState('');

  const matches = useMediaQuery('(min-width: 768px)');

  const loadingTable = useMemo(() => loading.some(url => url
      === `/admin/${type}?limit=${rowsPerPage}&offset=${page * rowsPerPage}&from=${from}&to=${to}${
        JSON.parse(Helpers.token.get('table:search')) ? `&search=${Helpers.token.get('table:search')}` : ''
      }`), [from, loading, page, rowsPerPage, to, type]);
  const loadingUpdatePermission = useMemo(() => loading.some(url => url === '/admin/permission/update'), [loading]);
  const searchKeys = useMemo(() => ({
    admin_id: '',
    ...(
      {
        email: '', name: '',
      }
    ),
  }), []);

  const findIndexOfLongest = useCallback(arr => {
    let det = [0, 0]; // [index, length]
    arr.forEach((curr, i) => {
      const len = Object.keys(curr).length;
      det = len > det[1] ? [i, len] : det;
    });
    return det[0];
  }, []);

  const getData = useCallback((clear = true) => {
    if (clear) {
      clearAll();
    }

    if (Helpers.permission(['read-admin-permissions'], permissions)) {
      setTimeout(() => {
        getAll(
          type,
          rowsPerPage,
          page * rowsPerPage,
          Helpers.token.get('date:from'),
          Helpers.token.get('date:to'),
          JSON.parse(Helpers.token.get('table:search')) ? Helpers.token.get('table:search') : null,
          res => {
            setIsTyping(false);
            const indexOfLongest = findIndexOfLongest(res.data.all);
            setKeys(Object.keys(res.data.all[indexOfLongest]));
          },
          () => {
            setIsTyping(false);
          }
        );
      }, 1000);
    }
  }, [permissions, clearAll, getAll, rowsPerPage, page, findIndexOfLongest]);

  useEffect(() => {
    if (firstLoad) {
      Helpers.token.set(null, 'table:search');
      setFirstLoad(false);
    } else {
      getData();
    }
  }, [firstLoad, getData]);

  // format is '{"lastname":"Nna"}' or `{"lastname":"Nna"}`
  const handleSearch = useCallback((e, source) => {
    let searchValue = {};
    if (source === 'search') {
      searchValue = Object.keys(searchKeys).reduce((prev, current) => ({
        ...prev, [current]: e.target.value,
      }), {});
      if (status !== 'all') {
        searchValue.status = status;
      }
    } else {
      searchValue = JSON.parse(Helpers.token.get('table:search')) ? JSON.parse(Helpers.token.get('table:search')) : {};
      if (source === 'status') {
        if (e.target.value === 'all') {
          delete searchValue.status;
        } else {
          searchValue.status = e.target.value;
        }
      }
    }

    if (Object.keys(searchValue).length === 0) {
      Helpers.token.set(null, 'table:search');
    } else {
      Helpers.token.set(JSON.stringify(searchValue), 'table:search');
    }

    // eslint-disable-next-line no-console
    console.debug('Searching With:', searchValue);
  }, [searchKeys, status]);

  const handleInputSearch = useCallback(e => {
    clearTimeout(dateTimeout);

    setSearch(e.target.value);
    handleSearch(e, 'search');

    setDateTimeout(setTimeout(() => {
      if (page === 0) {
        getData();
      } else {
        setPage(0);
      }
      setDateTimeout(null);
    }, 2000));
  }, [dateTimeout, getData, handleSearch, page]);

  const handleClearSearch = useCallback(() => {
    Helpers.token.set(null, 'table:search');
    setStatus('all');
    setSearch('');
    getData();
  }, [getData]);

  const setDate = useCallback(which => val => {
    clearTimeout(dateTimeout);
    if (which === 'to') {
      setTo(val);
      Helpers.token.set(val, 'date:to');
    } else if (which === 'from') {
      setFrom(val);
      Helpers.token.set(val, 'date:from');
    }
    setDateTimeout(setTimeout(() => {
      getData();
      setDateTimeout(null);
    }, 1000));
  }, [getData, dateTimeout]);

  // const goTo = useCallback((e, newValue) => {
  //   history.push(`/${type}/${newValue[`${type}_id`]}`);
  // }, [history, type]);

  useEffect(() => {
    setTimeout(() => isTyping && setIsTyping(false), 1000);
  }, [isTyping]);

  const reload = useCallback(() => {
    window.location = `/${type}`;
    return null;
  }, [type]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(event => {
    setRowsPerPage(+event.target.value);
    Helpers.token.set(+event.target.value, `${type}:rows_per_page`);
    setPage(0);
  }, [type]);

  const handleSetPermissionValue = useCallback((e, admin_id) => {
    setActionID(admin_id);
    setPermissionValue(e.target.value.trim());
  }, []);

  const [permissionModalOpen, setPermissionModalOpen] = useState(false);
  const handlePermissionModalOpen = useCallback(() => setPermissionModalOpen(true), []);
  const handlePermissionModalClose = useCallback(() => {
    setPermissionValue('');
    setActionID(0);
    setActionEmail('');
    setPermissionModalOpen(false);
  }, []);

  const handlePermissionUpdateSubmit = useCallback((event, admin_id, adminEmail) => {
    event.preventDefault();
    setActionID(admin_id);
    setActionEmail(adminEmail);
    if (permissionValue) {
      if (Helpers.permission(['update-admin-permissions'], permissions)) {
        handlePermissionModalOpen();
      } else {
        Helpers.notification.error('You do not have the necessary permission(s): [update-admin-permissions]');
      }
    } else {
      Helpers.notification.error('No updates were made');
    }
  }, [handlePermissionModalOpen, permissionValue, permissions]);

  const permissionUpdate = useCallback(addOrDelete => {
    if (permissionValue) {
      const permissionArr = permissionValue.replaceAll('"', '').split(',').map(item => item.trim());
      updatePermission(
        actionID,
        addOrDelete,
        permissionArr,
        res => {
          getData();
          Helpers.notification.success(res.message);
        },
        err => {
          Helpers.notification.error(err.message);
        }
      );
    } else {
      Helpers.notification.error('No permission to update.');
    }
    setPermissionValue('');
    setActionID(0);
    setActionEmail('');
    handlePermissionModalClose();
  }, [actionID, getData, handlePermissionModalClose, permissionValue, updatePermission]);

  // Set extra columns on the table
  all.map((item, i) => {
    const obj = {
      ...item,
      update: Helpers.permission(['update-admin-permissions'], permissions),
      // eslint-disable-next-line sort-keys-fix/sort-keys-fix, sort-keys
      action: Helpers.permission(['update-admin-permissions'], permissions),
    };
    all[i] = Object.keys(obj).filter(key => !!obj[key]).reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: obj[currentValue],
      }),
      {}
    );
    return all[i];
  });

  const totalObj = {
    admin: {
      amount: 0,
      total: 0,
    },
  };

  if (total && total.length > 0) {
    totalObj[type].total = total[0].total;
    if (type === 'loan' || type === 'repayment') {
      totalObj[type].principal = total[0].principal;
      totalObj[type].interest = total[0].interest;
    }
    if (type === 'withdrawal' || type === 'portfolio') {
      totalObj[type].amount = total[0].amount;
    }
  }

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar>
          <Toolbar
            sx={{
              background: 'white',
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{
                flexDdirection: 'row',
                mr: 2,
              }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { sm: 'block', xs: 'none' }, flexGrow: 1 }}
            >
              carrot
            </Typography>

            <FormControl
              sx={{ width: matches ? 'calc(100% - 240px)' : '100%' }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                onChange={handleInputSearch}
                value={search}
                endAdornment={(
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                    )}
                label="search"
              />
            </FormControl>
          </Toolbar>
        </AppBar>
        {/* <Toolbar /> */}
      </Box>

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <Menu
          {...props}
          email={email}
          open={mobileOpen}
          width={drawerWidth}
          toggle={handleDrawerToggle}
        />

        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
          style={{ maxWidth: '100%' }}
        >
          {/* <Toolbar /> */}
          <Typography
            sx={{
              fontSize: '17px',
              m: 0.25,
              p: 0,
            }}
            style={{ marginTop: 60 }}
          >
            SEARCHING FOR:
            {' '}
            {JSON.parse(Helpers.token.get('table:search')) && JSON.parse(Helpers.token.get('table:search'))[`${type}_id`] ? `${type}_id= ${JSON.parse(Helpers.token.get('table:search'))[`${type}_id`]};` : ''}
            {' '}
            {JSON.parse(Helpers.token.get('table:search')) && JSON.parse(Helpers.token.get('table:search')).status ? `status= ${JSON.parse(Helpers.token.get('table:search')).status};` : ''}
            {' '}
            <img src={errorImg} onClick={handleClearSearch} alt="cancel" style={{ cursor: 'pointer', marginBottom: '3px', width: '15px' }} />
          </Typography>

          <Typography
            sx={{
              fontSize: 'h5.fontSize',
              m: 0.25,
              p: 0.5,
            }}
            style={{ marginTop: 30 }}
          >
            {Helpers.capitalizeFirstLetter(type)}
            S (Page
            {' '}
            {page + 1}
            )
          </Typography>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: {
                lg: 'row',
                md: 'row',
                sm: 'column',
                xs: 'column',
              },
              justifyContent: 'right',
              m: 2,
              maxWidth: '100%',
            }}
          >
            <Typography sx={{
              m: 1,
              p: 1,
            }}
            >
              From
            </Typography>

            <DatePicker
              value={from}
              onChange={setDate('from')}
            />

            <Typography sx={{
              m: 1,
              p: 1,
            }}
            >
              To
            </Typography>

            <DatePicker
              value={to}
              onChange={setDate('to')}
            />

            <Box
              sx={{
                ml: 2,
              }}
            >
              <CSVLink
                data={all}
                filename={`${type}s-${from}-to-${to}-pg-${page + 1}.csv`}
                className="btn btn-primary csvButton"
              >
                {Helpers.capitalizeFirstLetter('Export as CSV')}
              </CSVLink>
            </Box>

          </Box>

          <Box sx={{ display: (type === 'loan' || type === 'withdrawal') ? 'flex' : '', width: 10 }}>
            <DashboardCard title={`TOTAL ${Helpers.capitalizeFirstLetter(type)}S`} value={totalObj[type]} type={type} />
          </Box>

          {
                loadingTable || dateTimeout
                  ? <Loading size="big" />
                  : (
                    <Paper sx={{ overflow: 'hidden', width: '100%' }}>
                      <TableContainer sx={{ maxHeight: '80vh' }}>
                        <Table stickyHeader aria-label="sticky table">
                          <caption>
                            {
                        all.length === 0
                          ? (
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                m: 2,
                              }}
                            >
                              <span>
                                There are no
                                {' '}
                                {type}
                                s for the selected period.
                              </span>
                              <MuiButton variant="outlined" color="info" size="medium" onClick={reload}>
                                Refresh
                              </MuiButton>
                            </Box>
                          ) : `These are ${type}s for the selected period only.`
                      }
                          </caption>

                          <TableHead>
                            <TableRow>
                              {all.length === 0 || loadingTable ? null : keys.map((key, i) => {
                                const heading = key;
                                return (
                                  <TableCell
                                    key={i}
                                    align="center"
                                    style={{ minWidth: 150 }}
                                  >
                                    {Helpers.capitalizeFirstLetter(heading)}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {all.length === 0 ? null : loadingTable
                              ? <Loading size="big" />
                              : all
                                .map((item, i) => (
                                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                    {keys.map((key, j) => {
                                      const val = item[key];
                                      return (
                                        <TableCell key={j} align="center" title={typeof val === 'object' || typeof val === 'boolean' ? '' : val}>
                                          {
                                            (Helpers.permission(['update-admin-permissions'], permissions) && type === 'admin' && key === 'update')
                                              ? (
                                                <TextField
                                                  id="outlined-basic"
                                                  variant="outlined"
                                                  name=""
                                                  value={item.admin_id === actionID ? permissionValue : ''}
                                                  placeholder="permission"
                                                  onChange={e => handleSetPermissionValue(e, item.admin_id)}
                                                />
                                              )
                                              : (Helpers.permission(['update-admin-permissions'], permissions) && type === 'admin' && key === 'action')
                                                ? (
                                                  <Button
                                                    type="info"
                                                    loading={item.admin_id === actionID && loadingUpdatePermission}
                                                    onClick={e => handlePermissionUpdateSubmit(e, item.admin_id, item.email)}
                                                  >
                                                    Add/Delete
                                                  </Button>
                                                )
                                                : typeof val === 'object'
                                                  ? JSON.stringify(val)
                                                  : val
                                        }
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 2000]}
                        component="div"
                        count={parseInt(totalObj[type].total, 10)}
                        rowsPerPage={rowsPerPage}
                        page={parseInt(totalObj[type].total, 10) <= 0 ? 0 : page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  )
              }

        </Box>
        <Box
          sx={{
            display: Helpers.permission(['update-admin-permissions'], permissions) ? 'block' : 'none',
            textAlign: 'left',
          }}
        >
          <div>
            <SingleModal
              modalName="updatePermission"
              modalOpen={permissionModalOpen}
              modalTitle={
                    `Do you want to ADD or DELETE this permission for ADMIN ${actionID} (${actionEmail}):
                    \n
                    ${permissionValue}`
                }
              defaultButtons={(
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    mt: 3,
                  }}
                >
                  <Button
                    onClick={() => permissionUpdate('add')}
                    type="success"
                  >
                    add
                  </Button>
                  <Button
                    onClick={() => permissionUpdate('delete')}
                    type="secondary"
                  >
                    delete
                  </Button>
                </Box>
                  )}
              modalClose={handlePermissionModalClose}
            />
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default Permissions;
