import React, { Component } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import './Signin.css';

import * as Helpers from '../../helpers';

class Signin extends Component {
  state = {
    email: '',
    password: '',
  };

  handleSuccess = async response => {
    const { email, password } = this.state;
    const { signin, load, history } = this.props;
    const google_token = response.credential;
    signin(email, password, google_token, () => {
      history.push('/dashboard');
      load();
    });
  };

  handleError = async error => {
    // eslint-disable-next-line no-console
    console.log(error);
    Helpers.notification.error('Google account verification failed.');
  };

  onChange = field => e => {
    this.setState({ [field]: e.target.value });
  };

  render() {
    return (
      <div className="App">
        <div className="logo-brand" style={{ marginLeft: '15px' }}>
          <svg width="86" height="22" viewBox="0 0 86 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3259 17.1918L14.5993 19.377C13.0554 21.0092 10.899 22 8.47966 22C3.84623 22 0 18.1538 0 13.5203C0 8.88688 3.81737 5.04065 8.47966 5.04065C10.8397 5.04065 12.9961 5.97375 14.54 7.57541L12.2682 9.76066C11.3062 8.74098 9.96589 8.1574 8.47966 8.1574C5.44949 8.1574 3.1472 10.6633 3.1472 13.5187C3.1472 16.4623 5.56653 18.8512 8.47966 18.8512C9.99475 18.8528 11.3655 18.2115 12.3259 17.1918Z" fill="#353535" />
            <path d="M32.402 21.3587H29.2836V16.2603L27.5938 21.1551C26.4571 21.7082 25.2338 22 23.9223 22C19.2889 22 15.4426 18.1826 15.4426 13.4914C15.4426 8.85801 19.26 5.01178 23.9223 5.01178C25.2338 5.01178 26.4571 5.30357 27.5938 5.8567L29.2836 10.7226V5.62423H32.402V21.3587ZM23.9223 8.15899C20.9787 8.15899 18.5898 10.5479 18.5898 13.4914C18.5898 16.4062 20.9498 18.8528 23.9223 18.8528C26.8659 18.8528 29.2836 16.4639 29.2836 13.4914C29.2836 10.519 26.8947 8.15899 23.9223 8.15899Z" fill="#353535" />
            <path d="M44.319 5.01178V8.15899C43.0076 8.15899 41.8131 8.68326 40.9682 9.52818C40.1233 10.3731 39.599 11.5675 39.599 12.879V21.3587H36.4518V5.62423H39.599V10.1406L41.6384 5.47833C42.541 5.15768 43.4148 5.01178 44.319 5.01178Z" fill="#353535" />
            <path d="M54.1952 5.01178V8.15899C52.8837 8.15899 51.6893 8.68326 50.8443 9.52818C49.9994 10.3731 49.4752 11.5675 49.4752 12.879V21.3587H46.3279V5.62423H49.4752V10.1406L51.5145 5.47833C52.4187 5.15768 53.2925 5.01178 54.1952 5.01178Z" fill="#353535" />
            <path d="M72.2896 13.5203C72.2896 18.1826 68.4434 22 63.8099 22C59.1765 22 55.3303 18.1538 55.3303 13.5203C55.3303 8.88688 59.1476 5.04065 63.8099 5.04065C68.5027 5.04065 72.2896 8.82916 72.2896 13.5203ZM69.144 13.5203C69.144 10.5479 66.784 8.159 63.8115 8.159C60.7814 8.159 58.4791 10.6649 58.4791 13.5203C58.4791 16.4639 60.8984 18.8528 63.8115 18.8528C66.7535 18.8528 69.144 16.4928 69.144 13.5203Z" fill="#353535" />
            <path d="M85.9847 8.77146H81.2936V21.3587H78.1463V8.77146H73.4263V5.62425H78.1463V0H81.2936V5.62425H85.9847V8.77146Z" fill="#353535" />
          </svg>
        </div>
        <div className="login-box">
          <h2>Login</h2>
          <form>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <GoogleLogin onSuccess={this.handleSuccess} onError={this.handleError} />
            </GoogleOAuthProvider>
          </form>
        </div>
      </div>
    );
  }
}

export default Signin;
