import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';

export default function SearchAppBar(props) {
  const { onToggleDrawer } = props;
  const handleDrawerToggle = () => {
    onToggleDrawer();
  };
  const { buttonName } = props;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{
              flexDdirection: 'row',
              mr: 2,
            }}
            // eslint-disable-next-line react/destructuring-assignment
            onClick={buttonName === 'back' ? props.back : handleDrawerToggle}
          >
            {buttonName === 'back'
              ? (
                <>
                  <ArrowBackIcon />
                  <Typography sx={{ fontSize: 10 }}>
                    back
                  </Typography>

                </>
              )
              : <MenuIcon />}
          </IconButton>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { sm: 'block', xs: 'none' }, flexGrow: 1 }}
          >
            carrot
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}
